:root {
  --text-4xl: 36px;
  --leading-4xl: 40px;
  --text-2xl: 24px;
  --leading-2xl: 32px;
  --text-xl: 20px;
  --leading-xl: 28px;
  --text-lg: 18px;
  --leading-lg: 28px;
  --text-md: 16px;
  --leading-md: 24px;
  --text-sm: 14px;
  --leading-sm: 20px;
  --text-xs: 12px;
  --leading-xs: 16px;
}
