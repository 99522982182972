:root {
  --primary: #4318ff;
  --primary10: #ece8ff;
  --black: #000;
  --white: #fff;
  --gray50: #fafafa;
  --gray100: #f5f5f5;
  --gray200: #e5e5e5;
  --gray300: #d4d4d4;
  --gray400: #a3a3a3;
  --gray500: #737373;
  --gray600: #525252;
  --gray700: #404040;
  --gray800: #262626;
  --gray900: #171717;
  --gray950: #0a0a0a;
  --red50: #fef2f2;
  --red100: #fee2e2;
  --red200: #fecaca;
  --red300: #fca5a5;
  --red400: #f87171;
  --red500: #ef4444;
  --red600: #dc2626;
  --red700: #b91c1c;
  --red800: #991b1b;
  --red900: #7f1d1d;
  --red950: #450a0a;
  --orange50: #fff7ed;
  --orange100: #ffedd5;
  --orange200: #fed7aa;
  --orange300: #fdba74;
  --orange400: #fb923c;
  --orange500: #f97316;
  --orange600: #ea580c;
  --orange700: #c2410c;
  --orange800: #9a3412;
  --orange900: #7c2d12;
  --orange950: #431407;
  --yellow50: #fefce8;
  --yellow100: #fef9c3;
  --yellow200: #fef08a;
  --yellow300: #fde047;
  --yellow400: #facc15;
  --yellow500: #eab308;
  --yellow600: #ca8a04;
  --yellow700: #a16207;
  --yellow800: #854d0e;
  --yellow900: #713f12;
  --yellow950: #422006;
  --green50: #f0fdf4;
  --green100: #dcfce7;
  --green200: #bbf7d0;
  --green300: #86efac;
  --green400: #4ade80;
  --green500: #22c55e;
  --green600: #16a34a;
  --green700: #15803d;
  --green800: #166534;
  --green900: #14532d;
  --green950: #052e16;
  --blue50: #eff6ff;
  --blue100: #dbeafe;
  --blue200: #bfdbfe;
  --blue300: #93c5fd;
  --blue400: #60a5fa;
  --blue500: #3b82f6;
  --blue600: #2563eb;
  --blue700: #1d4ed8;
  --blue800: #1e40af;
  --blue900: #1e3a8a;
  --blue950: #172554;
}
